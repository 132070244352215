@use "constants";

html, body {
  height: 100%;
}

body {
  margin: 0;
  background-color: var(--surface-ground);
}

.container-max {
  width: 100%;
  max-width: constants.$container-max-width;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;
}

.w-100 {
  width: 100%;
}

.cta-container {
  margin: 2rem 0;
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

input:disabled + label {
  color: var(--surface-500);
}

.aux-page, .text-center {
  text-align: center;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: var(--surface-0);
}

::-webkit-scrollbar-thumb {
  background-color: var(--surface-400);
  border-radius: 3px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.w-50 {
  width: 50% !important;
  display: block;
}

.markdown-link {
  cursor:pointer;
  text-decoration: underline;
  font-size: 1.5rem;
  padding-top: 1rem;
  display:block;
}

markdown {
  ::ng-deep p {
    line-height: 1.2;
    
    
  }

  p strong {
    font-weight: 600;
  }
}

.absolute-center {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
