@use '../../app/styles/breakpoints';

.p-message-summary {
  text-transform: capitalize;
}

.p-checkbox-label {
  margin-left: 0.5rem !important;
  margin-bottom: 0 !important;
}

.p-calendar {
  width: 100%;
}

.p-datepicker:not(.default-top) {
  top: -50px !important;
}

.p-datepicker-calendar .p-disabled {
  opacity: 0.5;
}

.p-button {
  text-transform: uppercase;
  font-weight: 600;

  &.p-button-icon-only {
    &.auto-width {
      width: initial;
      padding: 0.5rem 1rem;
    }
  }

  &.p-togglebutton {
    padding: 0.75rem 1rem;

    .pi:before {
      font-size: 1rem;
    }

    .p-button-icon-right:not(.pi), .p-button-icon-left:not(.pi) {
      display: none;
    }
  }

  &.row-toggler {
    width: 2rem !important;
    height: 2rem !important;
  }
}

.p-sidebar .p-sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/** Dropdown & autocomplete styles start **/
.p-autocomplete-panel .p-autocomplete-items, .p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
}

.p-autocomplete .p-autocomplete-panel, .p-dropdown .p-dropdown-panel {
  border-radius: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item, .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 1.25rem !important;
  text-transform: capitalize;
}

.p-dropdown-items-wrapper, .p-autocomplete-items-wrapper {
  max-height: 225px !important;
}

.p-dropdown-item-group {
  text-align: center;
  text-transform: capitalize;
  background-color: var(--gray-100) !important;

}

.p-dropdown-trigger {
 margin-bottom: initial !important;
}

/** Dropdown styles end **/

small.p-error {
  margin-top: 3px;
  line-height: 1.2;
}

.p-steps {
  &.no-label {
    .p-steps-item:before {
      top: 75% !important;
    }
  }

  .p-steps-item:before {
    top: 57% !important;
  }
}

@include breakpoints.bp-to(m) {
  .p-steps-item:not(.p-steps-current) .p-steps-title {
    display: none;
  }

  .p-message-summary {
    display: none;
  }
}

.p-steps-item {
  .p-menuitem-link .p-steps-number {
    background-color: var(--green-200);
    border-color: var(--green-200);
  }

  &.p-steps-current {
    .p-menuitem-link .p-steps-number {
      background-color: var(--blue-200);
      border-color: var(--blue-200);
    }
  }

  &.p-steps-current {
    & ~ .p-steps-item {
      .p-menuitem-link .p-steps-number {
        background-color: inherit;
        border-color: inherit;
      }
    }
  }
}

.p-button.p-button-outlined {
  font-weight: 600;
}

.p-button.p-button-outlined.dark-background {
  background-color: white;
}

.p-fileupload {
  &.file-upload {
    .p-button-label {
      &:before {
        content: ".";
        font-weight: 700;
        font-size: 2rem;
        position: relative;
        top: -3px;
        margin-right: 0.5rem;
      }
    }
  }

  &.file-upload-complete {
    .p-button-label {
      &:after {
        content: '\e90a';
        font-family: 'primeicons' !important;
        color: var(--green-500);
        font-size: 1.25rem;
        margin-left: 0.5rem;
        position: relative;
        top: 2px;
      }
    }
  }

  .p-button.p-button-icon-only {
    width: 32px !important;
    height: 1.5rem;
  }

  p-progressbar {
    display: none;
  }

  .p-fileupload-choose, .p-fileupload-choose:hover {
    background: none !important;
    color: var(--blue-900) !important;
    text-transform: initial !important;
    border: 0 !important;
    text-decoration: none;
    padding: 0.5rem !important;

    .p-button-icon {
      display: none;
    }
  }

  .p-fileupload-buttonbar {
    background: none !important;
    padding: 0 !important;
    border: 0 !important;
    position: relative;
    top: 0.5rem;
    z-index: 1;
  }

  .p-fileupload-content {
    padding: 0 !important;
    border: 0 !important;
  }

  &-row {
    margin-left: 0.5rem;

    div {
      padding: 0.5rem 0 !important;

      @include breakpoints.bp-from(m) {
        width: inherit !important;
        white-space: nowrap;
        margin-right: 0.5rem;
      }
    }

    div:first-child {
      display: none;

      & + div {
        width: 100%;
      }
    }
  }
}

.p-datatable {
  a {
    color: var(--blue-500);
    font-weight: 500;
  }

  .hide {
    display: none;
  }


  .p-datatable-wrapper {
    background-color: #FFF;
  }

  .p-sortable-column .p-sortable-column-icon {
    margin-left: 0 !important;
  }

  .p-datatable-thead > tr > th {
    padding: 0.2rem 0.5rem !important;
    vertical-align: middle;
    white-space: nowrap;
  }

  .p-datatable-tbody > tr > td {
    padding: 0.3rem 0.5rem !important;
    vertical-align: middle;
  }

  .p-datatable-header {
    padding: 0.5rem 1rem !important;
  }
}

.p-dialog {
  .p-dialog-header {
    display: none !important;
  }

  .p-dialog-content {
    padding: 1.5rem !important;
  }

  .p-dialog-content:last-of-type {
    border-radius: 6px;
  }

  &.fullscreen {
    width: 90%;
  }
}

.p-inputtext {
  &:disabled {
    opacity: 0.6;
    pointers: none;
  }
}

.p-tabmenu {
  &.disabled {
    opacity: 0.6;
    pointers: none;
  }

  .p-tabmenu-nav {
    background: none !important;

    .p-tabmenuitem {
      margin-right: 0.3rem !important;
      margin-top: 3px;
      box-shadow: rgb(50 50 93 / 25%) 0px 2px 10px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;

      &:first-child {
        margin-left: 3px;
      }

      &.p-highlight .p-menuitem-link {
        background: var(--surface-0) !important;
      }

      .p-menuitem-link {
        position: relative;
        padding: 0.8rem !important;
        text-transform: uppercase;
        border-width: 0 !important;
        background: var(--surface-200) !important;
        display: flex;
        flex-direction: row-reverse;
        gap: 0.5rem;

        &:not(.p-disabled):focus {
          box-shadow: none !important;
        }

        .p-menuitem-icon {
          margin-right: 0 !important;
          margin-left: 0.25rem;
        }
      }
    }
  }
}

.pi:before {
  font-size: 1.2rem;
}

.pi-clock {
  color: var(--red-500);
}

.pi-check-circle, .pi-check, .pi-check-square {
  color: var(--green-500);
}

.pi-times-circle, .pi-times {
  color: var(--red-500);
}

.pi-exclamation-circle, .pi-exclamation {
  color: var(--yellow-500);
}

.p-paginator-left-content:empty, .p-paginator .p-paginator-current:empty {
  display: none;
}

@include breakpoints.bp-to(m) {
  .p-paginator-current, .p-paginator-rpp-options {
    display: none !important;
  }
  .p-paginator-element {
    min-width: 1.8rem !important;
    height: 1.8rem !important;
  }
  .p-paginator {
    padding: 0.5rem 0 !important;
  }
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled) .p-inputswitch-slider {
  background: var(--green-500) !important;
}

.p-inputswitch.p-inputswitch-checked.p-disabled .p-inputswitch-slider {
  background: var(--surface-300) !important;
  pointer-events: none;
}

small.hint {
  margin-top: 0.5rem;
  font-size: 0.825rem;
}

small + i.pi {
  top: 1.25rem;
}

.field label {
  margin-bottom: 0.25rem;
  margin-left: 3px;
}
