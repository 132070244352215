lib-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & + .data-exists {
    display: none;
  }
}

.column-2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 2rem;
  width: 100%;

  h4 {
    text-align: center;
  }

  .col-1 {
    width: 50%;
  }

  .col-2 {
    width: 100%;
    margin-left: 2rem;
  }
}

.section {
  margin: 1rem 0;
  width: 100%;
  background: var(--gray-100);
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;

  &-action {
    &-edit {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      font-size: 1rem;
      text-transform: uppercase;
      color: var(--blue-500);
      font-weight: 600;
      cursor: pointer;

      i:before {
        font-size: 0.75rem;
        border-radius: 50%;
        border: 1px solid;
        padding: 0.25rem;
      }
    }
  }

  &-form {
    &-actions {
      display: flex;
      justify-content: center;;
      align-items: center;
      gap: 1rem;
    }
  }

  &-label {
    font-weight: 600;
    margin-right: 0.5rem;

    &:after {
      content: ':';
    }
  }

  &.data-table {
    padding: 0;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border: solid var(--blue-400) 1px;
    border-radius: 0.5rem;

    tr {
      background-color: white;
      border-bottom: 1px solid;

      &:nth-child(even) {
        background-color: var(--gray-50);
      }

      &:nth-child(odd) {
        background-color: white;
      }
    }


    th {
      font-weight: 600;
      padding: 0.25rem;
      text-transform: uppercase;
      background-color: var(--blue-400);
      border-top: none;
      color: white;
    }

    td, th {
      border-top: solid var(--blue-400) 1px;
    }

    td {
      text-align: center;
      padding: 0.5rem;

      &.data-left {
        text-align: left;
      }
    }

    tr:first-child {
      th:first-child {
        border-top-left-radius: 0.5rem;
      }

      th:last-child {
        border-top-right-radius: 0.5rem;
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 0.5rem;
      }

      td:last-child {
        border-bottom-right-radius: 0.5rem;
      }
    }

  }
}

.summary {
  .section {
    div {
      margin-bottom: 0.5rem;

      &.break {
        margin-top: 1rem;
      }
    }

    &-label {
      min-width: 25%;
      display: inline-block;
    }
  }
}

.custom-page-header {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__actions {
    lib-button:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.grand-summary {
  background-color: var(--blue-500);
  color: white;
  border-radius: 6px;
  border: 1px solid var(--blue-200);
  padding: 1rem;
  margin: 0 2rem;
  display: flex;
  justify-content: space-evenly;
  font-size: 1rem;
  text-transform: uppercase;

  .section-label {
    &:after {
      content: ':';
      margin-right: 1rem;
    }

    font-weight: 700;
  }
}

.label-value:not(:empty) {
  display: flex;
  gap: 0.5rem;

  &.wrap {
    flex-direction: column;

    .data-label {
      &:after {
        content: '';
      }
    }
  }

  .data-label {
    &:after {
      content: ':';
    }
  }

  .data-value {
    text-transform: capitalize;
    font-weight: 600;
  }
}
