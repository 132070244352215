@use "reset";
@use "constants";
@use "common";
@use "util";
@use "breakpoints";
@use "typography";
@use "modal";
@use "app";


html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
  z-index: 0;
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
