@use "./breakpoints";

.modal {
  &-data-list, &-description {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 2rem;
    display: block;
  }

  &-description {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.1;

    &.normal {
      font-weight: initial;
    }

    strong {
      font-weight: 600;
    }
  }

  &-data-list + &-description {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 2rem;
    display: block;
    text-align: left;
  }

  &-form {
    margin-bottom: 1rem;
  }

  &-actions {
    text-align: center;
    justify-content: center;
    display: flex;
    gap: 1rem;

    .action {
      margin-right: 0.5rem;
    }

    &:not(:first-child) {
      margin-right: 0;
    }

    .default-padding {
      padding: 1rem 1.5rem;
      line-height: 1.2;

      &.long-text {
        padding: 1rem;
      }
    }
  }

  &-form-fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    margin-bottom: 2rem;

    & > .form-field {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include breakpoints.bp-from(m) {
        flex-basis: 48%;
        min-width: 0;
      }

      ::ng-deep {
        label {
          margin-bottom: 0.25rem;
          margin-left: 3px;
        }
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    h4 {
      margin: 0;
    }
  }

  &-close-icon {
    cursor: pointer;
  }

  &-content {
    height: 100%;

    ul {
      margin-top: 0.5rem;
      list-style-type: disc;
      margin-left: 1.5rem;
    }

    h4 {
      display: inline-flex;
      align-items: center;
      margin-top: 0;
      text-transform: uppercase;
      margin-right: 0.5rem;

      i {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        border: 1px solid var(--gray-900);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
      }
    }
  }
}

.iframe-modal {
  height: 100%;
  width: 95% !important;
  max-width: 440px;

  .p-dialog-content {
    padding: 0.5rem !important;
  }
}
