.argyle {

  [id^=argyle-link-root] {
    display: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }

  .p-dialog {
    max-height: 100% !important;
  }

  .p-component-overlay {
    background: rgba(224, 224, 224, 0.9) !important;
  }

  .p-dialog-content {
    padding: 0 20px !important;
    border-radius: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.05) -2px 0px 4px 0px !important;
  }

  .payroll-modal-content {
    markdown {
      ::ng-deep {
        color: rgb(106, 119, 138) !important;
      }
    }

    lib-input ::ng-deep input {
      background: red;
    }

    .payroll-button {
      width: 100%;
      display: flex;
      justify-content: center;
      text-transform: capitalize;
      background-color: rgb(49, 58, 73);
      color: white;
      Padding: 13px 16px;
      height: 54px;
      font-size: 20px;

      &:hover, &:active {
        background-color: rgb(49, 58, 73);
        box-shadow: none;
      }

      .p-button-label {
        display: none;
      }
    }
  }
}