@use "./breakpoints";

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;

  @include breakpoints.bp-to(m) {
    margin: 1rem 0 0.5rem;

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.75rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1.25rem;
    }

    h5 {
      font-size: 1rem;
    }

    h5 {
      font-size: 0.75rem;
    }
  }
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}
