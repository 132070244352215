$breakpoints: (
        xs: 0px,
        s: 480px,
        m: 768px,
        l: 1024px,
        xl: 1200px
);

$gutters: (
        s: 3rem,
        m: 3rem,
        l: 6rem,
        xl: 6rem
);


/**
 * All breakpoints from a given one up.
 * @param {string} $name
 */
@mixin bp-from($name) {
  $width: map-get($breakpoints, $name);
  @media (min-width: $width) {
    @content;
  }
}

/**
 * All breakpoints up to a given one.
 * @param {string} $name
 */
@mixin bp-to($name) {
  $width: map-get($breakpoints, $name) - 1;
  @media (max-width: $width) {
    @content;
  }
}

/**
 * All breakpoints from a given name up to a second name.
 * @param {string} $start
 * @param {string} end
 */
@mixin bp-between($start, $end) {
  $from: map-get($breakpoints, $start);
  $to: map-get($breakpoints, $end) - 1;
  @media (min-width: $from) and (max-width: $to) {
    @content;
  }
}

/**
 * Target a specific breakpoint.
 * @param {string} $name
 */
@mixin bp-only($name) {
  $from: map-get($breakpoints, $name);
  $to: nextBreakpointValue($name) - 1;
  @media (min-width: $from) and (max-width: $to) {
    @content;
  }
}
