@use "sass:meta";
@layer app, primeng;
/* You can add global styles to this file, and also import other style files */
/*@import "tailwindcss/base" layer(tailwindcss);*/
//@import "tailwindcss/components" layer(tailwindcss);
//@import "tailwindcss/utilities" layer(tailwindcss);
// // Custom styles
@layer app {
    @include meta.load-css("assets/sass/vendor/styles/index");
    @include  meta.load-css("assets/sass/app/styles/index");
}

// theme scss files - client specific

// Default theme loads from partnerDetails -> theme ( load default if not configured, else load themeUrl )
// Override styles follow theme/custom-{color}/styles

// Demo theme url ( "https://cdnjs.cloudflare.com/ajax/libs/primeng/14.1.2/resources/themes/lara-light-blue/theme.min.css" )
